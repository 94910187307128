<template>
  <div>
    <body-with-header ads title="توصیه های اختصاصی">
      <CRow class="h-100">
        <vue-element-loading
          :active="loading"
          :text="loadingText"
          spinner="bar-fade-scale"
          color="var(--secondary)"
        />
        <CCol md="12">
          <div class="tab-body-card" style="height: calc(100vh - 5vh)">
            <CRow class="mb-4 gap-3" align-vertical="end">
              <CCol md="5">
                <label>کارفرمایان</label>
                <vSelect
                  dir="rtl"
                  v-model="selectedFarmer"
                  :options="farmerOptions"
                  placeholder="انتخاب کارفرما..."
                  label="label"
                  track-by="value"
                ></vSelect>
              </CCol>
              <CCol md="5">
                <label>زمین های کارفرما</label>
                <vSelect
                  dir="rtl"
                  v-model="selectedFarm"
                  :options="farmOptions"
                  placeholder="انتخاب زمین..."
                  label="label"
                  track-by="value"
                ></vSelect>
              </CCol>
              <CCol md="2">
                <CButton
                  class="flex-grow-1"
                  color="success"
                  style="width: 100%"
                  @click="newTips()"
                >
                  ثبت توصیه جدید
                </CButton>
              </CCol>
            </CRow>
            <tips
              :farmerId="selectedFarmer.value"
              :farmId="selectedFarm.value"
              :farmName="selectedFarm.label"
              ref="tipsList"
            />
          </div>
        </CCol>
      </CRow>
    </body-with-header>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BodyWithHeader from "../../components/bodyWithHeader.vue";
import tips from "./subForm/tips.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { getCurrentUser } from "../../utils/index";

export default {
  name: "advicerTips",
  components: {
    BodyWithHeader,
    tips,
    vSelect,
  },
  data() {
    return {
      loading: false,
      loadingText: "",
      selectedFarmer: 0,
      farmerOptions: [],
      selectedFarm: 0,
      farmOptions: [],
    };
  },
  computed: {},
  methods: {
    ...mapActions("tips", ["GetAllEmployer", "GetAllEmployerFarms"]),
    async getAllEmployer() {
      this.farmerOptions = [];
      let userType = getCurrentUser().type;
      let result = await this.GetAllEmployer({ currentUserType: userType });
      if (result.succeeded) {
        this.farmerOptions = result.data.map((el) => {
          return { value: el.userTypeId, label: el.fullName };
        });
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: result.message,
          type: "error",
        });
      }
    },
    async getAllEmployerFarms() {
      this.farmOptions = [];
      let userType = getCurrentUser().type;
      let result = await this.GetAllEmployerFarms({
        farmerId: this.selectedFarmer.value,
        currentUserType: userType,
      });
      if (result.succeeded) {
        this.farmOptions = result.data.map((el) => {
          return { value: el.farmId, label: `${el.title} (${el.product})` };
        });
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: result.message,
          type: "error",
        });
      }
    },
    newTips() {
      if(this.selectedFarm){
        this.$refs.tipsList.newTips(0);
      }else{
        this.$notify({
          title: "پیام سیستم",
          text: "زمین را انتخاب کنید",
          type: "info",
        }); 
      }
    },
  },
  watch: {
    selectedFarmer: function () {
      this.getAllEmployerFarms();
    },
  },
  mounted() {
    this.getAllEmployer();
  },
  created() {},
};
</script>

<style scoped>
</style>
